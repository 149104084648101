import { useState, useMemo, useEffect } from 'react'

export default (makePromise, deps) => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [data, setData] = useState(null)

  const promise = useMemo(makePromise, deps)

  useEffect(() => {
    let latest = true
    setLoading(true)
    setError(null)

    promise
      .then(v => latest && setData(v))
      .catch(e => latest && setError(e))
      .finally(() => latest && setLoading(false))

    return () => latest = false
  }, [promise])

  return { loading, error, data }
}
