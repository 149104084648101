import React from 'react'
import { AuthenticationProvider } from './src/context/Authentication'
import { PageDataProvider } from '@src/context/PageDataContext'
import '@src/styles/index.scss'

export const wrapRootElement = ({ element }) => (
  <PageDataProvider>
    <AuthenticationProvider>
      {element}
    </AuthenticationProvider>
  </PageDataProvider>
)
