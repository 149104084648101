// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-components-templates-formpage-formpage-js": () => import("./../../../src/components/templates/Formpage/Formpage.js" /* webpackChunkName: "component---src-components-templates-formpage-formpage-js" */),
  "component---src-components-templates-news-page-news-page-js": () => import("./../../../src/components/templates/NewsPage/NewsPage.js" /* webpackChunkName: "component---src-components-templates-news-page-news-page-js" */),
  "component---src-components-templates-page-page-js": () => import("./../../../src/components/templates/Page/Page.js" /* webpackChunkName: "component---src-components-templates-page-page-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-draft-js": () => import("./../../../src/pages/draft.js" /* webpackChunkName: "component---src-pages-draft-js" */),
  "component---src-pages-handle-login-js": () => import("./../../../src/pages/handle-login.js" /* webpackChunkName: "component---src-pages-handle-login-js" */),
  "component---src-pages-page-that-requires-login-js": () => import("./../../../src/pages/page-that-requires-login.js" /* webpackChunkName: "component---src-pages-page-that-requires-login-js" */),
  "component---src-pages-preview-js": () => import("./../../../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-pages-styleguide-js": () => import("./../../../src/pages/styleguide.js" /* webpackChunkName: "component---src-pages-styleguide-js" */)
}

