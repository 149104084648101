import React, { createContext, useState } from 'react'

const PageDataContext = createContext()

const PageDataProvider = ({ children }) => {
  const [pageTitle, setPageTitle] = useState('')

  return (
    <PageDataContext.Provider
      value={{ pageTitle, setPageTitle }}
    >
      {children}
    </PageDataContext.Provider>
  )
}

export { PageDataContext, PageDataProvider }