import format from 'date-fns/format'
import is from 'date-fns/locale/is'

export const API_URL = process.env.GATSBY_API_URL

export const FALLBACK_AMOUNT = 3000

export const DEFAULT_MONTHLY = [
  { amount: 3500 },
  { amount: 4500 },
  { amount: 5500 },
]

export const DEFAULT_SINGLE = [
  { amount: 5000 },
  { amount: 10000 },
  { amount: 20000 },
]

export const isExternalLink = (link) => {
  return !link.startsWith('/')
}

export const splitHeadings = (heading) => {
  return heading.split('|')
}

export const splitCardHeadings = (heading) => {
  return heading.split('- ■')
}

export const formatDateString = (date) => {
  if(!date) return ''
  return format(new Date(date), 'dd. MMMM yyyy', { locale: is })
}

export const formatCurrency = (amount) => {
  return amount.toFixed(0).replace(/(\d)(?=(\d{3})+\b)/g, '$1.')
}

export const formatSSN = (ssn) => {
  return `${ssn.slice(0, 6)}-${ssn.slice(6)}`
}

export const formatPhone = (phone) => {
  return phone.length === 7 ? `${phone.slice(0, 3)}-${phone.slice(3)}` : phone
}

export const formatCardNumber = (cardNumber) => {
  return `XXXX-${cardNumber.slice(cardNumber.length - 4)}`
}

export const sleep = (ms) => new Promise((r) => setTimeout(() => r(), ms))

export const doPostRedirect = (action, params) => {
  const form = document.createElement('form')
  form.action = action
  form.method = 'POST'
  form.acceptCharset = 'UTF-8'

  for (const [name, value] of Object.entries(params)) {
    const input = document.createElement('input')
    input.type = 'hidden'
    input.name = name
    input.value = value
    form.appendChild(input)
  }

  document.body.appendChild(form)
  form.submit()
}

export const seoPropertiesToProps = (properties) => {
  return properties
    .filter(({ type }) => type !== null)
    .reduce((acc, { type, value }) => {
      if (type === 'title') {
        acc.title = value
      } else if (type.match(/^og:/)) {
        acc.og = { ...(acc.og || {}), [type]: value }
      } else {
        acc.meta = { ...(acc.meta || {}), [type]: value }
      }
      return acc
    }, {})
}
