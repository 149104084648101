import React, { createContext, useContext, useState } from 'react'
import usePromise from '../hooks/usePromise'
import { API_URL } from '@src/utils'

const { GATSBY_HEIMSFORELDRI } = process.env

const AUTH_TOKEN = 'authToken'
const REMEMBER_LOCATION = 'rememberLocation'

const AuthenticationContext = createContext({ user: null, loading: true })

export const useAuthentication = () => useContext(AuthenticationContext)

const getStorage = () => window.localStorage

export const getToken = () => getStorage().getItem(AUTH_TOKEN)

export const setToken = token => getStorage().setItem(AUTH_TOKEN, token)

export const removeToken = () => getStorage().removeItem(AUTH_TOKEN)

// called before redirecting to island login to know where to send user when returning
export const rememberLocation = () => {
  const { pathname, search } = window.location
  getStorage().setItem(REMEMBER_LOCATION, pathname + search)
}

export const getPreviousLocation = () => {
  const path = getStorage().getItem(REMEMBER_LOCATION)
  getStorage().removeItem(REMEMBER_LOCATION)
  return path
}

export const AuthenticationProvider = ({ children }) => {
  const [localToken, setLocalToken] = useState(getToken())

  const { data, loading, error } = usePromise(async () => {
    if (!localToken) return

    const r = await fetch(`${API_URL}/api/user`, {
      method: 'POST',
      headers: {
        authentication: localToken ? 'Bearer ' + localToken : undefined
      }
    })
    return await r.json()
  }, [localToken])

  const user = data && data.user

  // for testing in development
  if (user && process.env.NODE_ENV !== 'production' && GATSBY_HEIMSFORELDRI) {
    user.heimsforeldri = GATSBY_HEIMSFORELDRI === '1'
  }

  const contextValue = {
    user,
    loading,
    error,
    setToken(token) {
      setToken(token)
      setLocalToken(token)
    }
  }

  return (
    <AuthenticationContext.Provider value={contextValue}>
      {children}
    </AuthenticationContext.Provider>
  )
}
